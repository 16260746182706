import { useCallback, useState } from "react";

import { WorkflowStatus } from "~/api/types.generated";
import { useUpdatePortfolioMutation } from "~/components/Portfolio/PortfolioAPI.generated";
import { PortfolioHeaderFragment } from "~/components/Portfolio/PortfolioHeader.generated";

export interface UpdatePortfolio {
  isOpen: boolean;
  loading: boolean;
  status: WorkflowStatus;
  name: string;
  enableProjects: boolean;
  enableQAndA: boolean;
  dealManager: string;
  systemSize: number;
  removeSystemSize: boolean;
  error: string | null;
  disabled: boolean;
  open(): void;
  submit(
    status: WorkflowStatus,
    name: string,
    enableProjects: boolean,
    enableQAndA: boolean,
    dealManager: string,
    systemSize: number,
    removeSystemSize: boolean
  ): Promise<void>;
  reset(): void;
  orgSlug: string;
}

/** Handles state and logic required for updating a portfolio */
export const useUpdatePortfolio = (
  orgSlug: string,
  portfolio: PortfolioHeaderFragment
): UpdatePortfolio => {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(
    portfolio.status || WorkflowStatus.Draft
  );
  const [name, setName] = useState(portfolio.name || "");
  const [enableProjects, setEnableProjects] = useState(
    portfolio.enableProjects || false
  );
  const [enableQAndA, setEnableQAndA] = useState(
    portfolio.enableQAndA || false
  );
  const [dealManager, setDealManager] = useState(
    portfolio.dealManager?.id || ""
  );
  const [systemSize, setSystemSize] = useState(portfolio.systemSize || 0);
  const [removeSystemSize, setRemoveSystemSize] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [request, { loading }] = useUpdatePortfolioMutation();

  const disabled = loading || error != null;

  const open = useCallback(() => setIsOpen(true), [setIsOpen]);

  const reset = useCallback(() => {
    setIsOpen(false);
    setStatus(portfolio.status || WorkflowStatus.Draft);
    setName(portfolio.name || "");
    setEnableProjects(portfolio.enableProjects || false);
    setEnableQAndA(portfolio.enableQAndA || false);
    setDealManager(portfolio.dealManager?.id || "");
    setSystemSize(portfolio.systemSize || 0);
    setRemoveSystemSize(false);
    setError(null);
  }, [portfolio]);

  const submit = useCallback(
    async (
      status: WorkflowStatus,
      name: string,
      enableProjects: boolean,
      enableQAndA: boolean,
      dealManager: string,
      systemSize: number,
      removeSystemSize: boolean
    ) => {
      if (disabled) return;
      try {
        const portfolioId = portfolio?.id;
        await request({
          variables: {
            orgSlug,
            portfolioId,
            status,
            name,
            enableProjects,
            enableQAndA,
            dealManagerId: dealManager,
            systemSize,
            removeSystemSize,
          },
          refetchQueries: ["PortfolioPage"],
        });
      } catch (error) {
        setError("A server error has occurred.");
      }
    },
    [request, orgSlug, portfolio, disabled]
  );

  return {
    isOpen,
    loading,
    status,
    name,
    enableProjects,
    enableQAndA,
    dealManager,
    systemSize,
    removeSystemSize,
    error,
    disabled,
    open,
    submit,
    reset,
    orgSlug,
  };
};
