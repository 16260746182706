import React from "react";
import isEqual from "react-fast-compare";
import { shallowEqualObjects } from "shallow-equal";
import styled from "styled-components";

import { DataGrid } from "~/components/DataGrid";
import QuestionPortfolios from "~/components/QAndA/components/QuestionPortfolios";
import { QAndASubjectFragment } from "~/components/QAndA/QAndA.generated";
import { GetPropsWithoutRef } from "~/utils/types";

import QuestionContext, {
  QuestionContextValue,
} from "../context/QuestionContext";
import QuestionAnswer from "./QuestionAnswer";
import QuestionBody from "./QuestionBody";
import QuestionCategories from "./QuestionCategories";
import QuestionNumber from "./QuestionNumber";
import QuestionProjects from "./QuestionProjects";
import QuestionStatus from "./QuestionStatus";

export interface QuestionRowProps
  extends GetPropsWithoutRef<typeof DataGrid.Row> {
  question: QuestionContextValue;
  subject: QAndASubjectFragment;
  canEditQuestions: boolean;
  canEditQuestionAnswers: boolean;
  selectedColumns: { [key: string]: boolean };
}

const { Row, Cell } = DataGrid;

const formatDate = (date: string) => {
  return new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(new Date(date));
};

const formatTime = (date: string) => {
  return new Intl.DateTimeFormat(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  }).format(new Date(date));
};

const QuestionRow = ({
  subject,
  question,
  canEditQuestions,
  canEditQuestionAnswers,
  selectedColumns,
  ...rest
}: QuestionRowProps) => {
  return (
    <QuestionContext.Provider value={question}>
      <Row
        inactive={question.isClosed}
        data-question-id={question.id}
        {...rest}
      >
        {selectedColumns.number && (
          <Cell
            numeric
            children={<QuestionNumber canEditQuestions={canEditQuestions} />}
          />
        )}
        {selectedColumns.status && (
          <Cell
            children={<QuestionStatus canEditQuestions={canEditQuestions} />}
          />
        )}
        {selectedColumns.category && <Cell children={<QuestionCategories />} />}
        {selectedColumns.project && subject.__typename === "Portfolio" && (
          <Cell children={<QuestionProjects />} />
        )}
        {selectedColumns.project && subject.__typename === "HoldingCompany" && (
          <Cell children={<QuestionPortfolios />} />
        )}
        {selectedColumns.question && (
          <WideCell
            children={<QuestionBody canEditQuestions={canEditQuestions} />}
          />
        )}
        {selectedColumns.answer && (
          <WideCell
            children={
              <QuestionAnswer canEditQuestionAnswers={canEditQuestionAnswers} />
            }
          />
        )}
        {selectedColumns.createdAt && (
          <Cell>
            <div>{formatDate(question.createdAt)}</div>
            <div>{formatTime(question.createdAt)}</div>
          </Cell>
        )}
        {selectedColumns.modifiedAt && (
          <Cell>
            <div>{formatDate(question.modifiedAt)}</div>
            <div>{formatTime(question.modifiedAt)}</div>
          </Cell>
        )}
      </Row>
    </QuestionContext.Provider>
  );
};

const WideCell = styled(Cell)`
  min-width: 250px;
  width: 50%;
`;

export default React.memo(
  QuestionRow,
  (
    { question: prevQuestion, subject: prevSubject, ...prevProps },
    { question: nextQuestion, subject: nextSubject, ...nextProps }
  ) => {
    return (
      isEqual(prevQuestion, nextQuestion) &&
      isEqual(prevSubject, nextSubject) &&
      shallowEqualObjects(prevProps, nextProps)
    );
  }
);
