import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { WorkflowStatus } from "~/api/types.generated";
import {
  StyledTable,
  TableContainer,
} from "~/components/Admin/Group/GroupsTable";
import { useUserInvitationSearch } from "~/components/Admin/User/useUserInvitationSearch";
import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { AddIcon } from "~/components/common/icons";
import { ThematicBreak } from "~/components/common/layout";
import Dialog from "~/components/generic/Dialog";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import {
  DraftPortfolioFragment,
  useCreatePortfolioMutation,
  useDeletePortfolioMutation,
  useDraftPortfoliosQuery,
  useOrgUsersQuery,
  useUpdatePortfolioMutation,
} from "~/components/Portfolio/PortfolioAPI.generated";
import { UpdatePortfolioDialog } from "~/components/Portfolio/UpdateDialog";
import SearchBar from "~/components/SearchBar";
import { fgColor } from "~/styles/mixins";

import { PortfolioMenu } from "./PortfolioMenu";

interface DraftPortfoliosPageProps {
  orgSlug: string;
}

const ClickableTd = styled.td`
  cursor: pointer;
  &:hover {
    ${fgColor.blue()};
    color: $link-color;
    text-decoration: none;
  }
`;

export const DraftPortfoliosPage = ({ orgSlug }: DraftPortfoliosPageProps) => {
  const search = useUserInvitationSearch();
  const { data, loading } = useDraftPortfoliosQuery({
    variables: {
      orgSlug,
      search: search.debouncedSearchTerm ? search.debouncedSearchTerm : "",
    },
  });
  const { data: usersData, loading: usersLoading } = useOrgUsersQuery({
    variables: { orgSlug },
  });
  const [updateRequest] = useUpdatePortfolioMutation();
  const [createPortfolio] = useCreatePortfolioMutation();
  const [deletePortfolio] = useDeletePortfolioMutation();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [portfolioName, setPortfolioName] = useState("");
  const [dealManagerId, setDealManagerId] = useState("");
  const [selectedPortfolio, setSelectedPortfolio] =
    useState<DraftPortfolioFragment | null>(null);

  const handleCreatePortfolio = async () => {
    try {
      await createPortfolio({
        variables: {
          orgSlug,
          name: portfolioName,
          dealManagerId: dealManagerId || null,
        },
        refetchQueries: ["DraftPortfolios"],
      });
      setIsCreateModalOpen(false);
      setPortfolioName("");
      setDealManagerId("");
    } catch (error) {
      console.error("Error creating portfolio:", error);
    }
  };

  const handleRowClick = (slug: string) => {
    const absolutePath = `${window.location.origin}/portfolios/${slug}`;
    window.location.href = absolutePath;
  };

  const handleSearchChange = useCallback(
    (e) => {
      search.setSearchTerm(e.target.value);
    },
    [search]
  );

  const handleEdit = (portfolio: DraftPortfolioFragment) => {
    setSelectedPortfolio(portfolio);
    setIsUpdateModalOpen(true);
  };

  const handleDelete = async (portfolioId: string) => {
    try {
      await deletePortfolio({
        variables: {
          orgSlug,
          portfolioId,
        },
        refetchQueries: ["DraftPortfolios"],
      });
    } catch (error) {
      console.error("Error deleting portfolio:", error);
    }
  };

  const handleUpdatePortfolio = async (
    status: WorkflowStatus,
    name: string,
    enableProjects: boolean,
    enableQAndA: boolean,
    dealManager: string,
    systemSize: number,
    removeSystemSize: boolean
  ) => {
    try {
      if (selectedPortfolio) {
        await updateRequest({
          variables: {
            orgSlug,
            portfolioId: selectedPortfolio.id,
            status,
            name,
            enableProjects,
            enableQAndA,
            dealManagerId: dealManager,
            systemSize,
            removeSystemSize,
          },
          refetchQueries: ["DraftPortfolios"],
        });
        setIsUpdateModalOpen(false);
        setSelectedPortfolio(null);
      }
    } catch (error) {
      console.error("Error updating portfolio:", error);
    }
  };

  const portfolios = data?.portfolios || [];

  return (
    <div>
      <h2>Portfolio Drafts</h2>
      <ThematicBreak />
      <div className="d-flex align-items-start justify-content-between mb-2 mt-3">
        <div className="col-5 p-0">
          <SearchBar
            keyword={search.searchTerm}
            onChange={handleSearchChange}
            renderInputGroupPrepend={() => (
              <div className="prepend">
                {search.searchPending ? (
                  <LoadingSpinner />
                ) : (
                  <i className="icon icon--search-dark" />
                )}
              </div>
            )}
            clearable
            onClear={() => search.clearSearch()}
          />
        </div>
        <div
          className="col d-flex justify-content-end"
          style={{ paddingRight: 0 }}
        >
          <PrimaryButton onClick={() => setIsCreateModalOpen(true)}>
            <AddIcon />
            <span>Add Portfolio</span>
          </PrimaryButton>
        </div>
      </div>
      <TableContainer>
        <StyledTable className="table mb-3">
          <thead>
            <tr>
              <th style={{ textAlign: "left", width: "40%" }}>Name</th>
              <th style={{ textAlign: "left", width: "40%" }}>Manager</th>
              <th style={{ textAlign: "left", width: "15%" }}>Created At</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {portfolios.map((portfolio) => (
              <tr key={portfolio.id}>
                <ClickableTd onClick={() => handleRowClick(portfolio.slug)}>
                  {portfolio.name}
                </ClickableTd>
                <td>{portfolio.dealManager?.fullName}</td>
                <td>{new Date(portfolio.createdDate).toLocaleDateString()}</td>
                <td>
                  <PortfolioMenu
                    portfolio={portfolio}
                    onEdit={() => handleEdit(portfolio)}
                    onDelete={() => handleDelete(portfolio.id)}
                    loading={loading}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TableContainer>
      <Dialog
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
        header="Create New Portfolio"
      >
        <div>
          <InputLabel>Name</InputLabel>
          <TextField
            variant="outlined"
            value={portfolioName || ""}
            onChange={(e) => setPortfolioName(e.target.value)}
            fullWidth
          />
          <FormControl variant="outlined" fullWidth style={{ marginTop: 16 }}>
            <InputLabel>Deal Manager</InputLabel>
            <Select
              value={dealManagerId || ""}
              onChange={(e) => setDealManagerId(e.target.value as string)}
              label="Deal Manager"
            >
              {usersLoading ? (
                <MenuItem value="">Loading...</MenuItem>
              ) : (
                usersData?.orgUsers?.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.fullName}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <div style={{ marginTop: 16, textAlign: "right" }}>
            <SecondaryButton onClick={() => setIsCreateModalOpen(false)}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={handleCreatePortfolio}
              style={{ marginLeft: 8 }}
            >
              Create
            </PrimaryButton>
          </div>
        </div>
      </Dialog>
      {selectedPortfolio && (
        <UpdatePortfolioDialog
          isOpen={isUpdateModalOpen}
          portfolioId={selectedPortfolio.id}
          status={selectedPortfolio.status ?? WorkflowStatus.Draft}
          name={selectedPortfolio.name}
          enableProjects={selectedPortfolio.enableProjects}
          enableQAndA={selectedPortfolio.enableQAndA}
          dealManager={selectedPortfolio.dealManager?.id || ""}
          systemSize={selectedPortfolio.systemSize || 0}
          removeSystemSize={false}
          error={null}
          disabled={false}
          orgSlug={orgSlug}
          loading={false}
          open={() => {}}
          reset={() => {
            setSelectedPortfolio(null);
            setIsUpdateModalOpen(false);
          }}
          submit={handleUpdatePortfolio}
        />
      )}
    </div>
  );
};
