import { Input, InputLabel } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";

import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { Toolbar } from "~/components/common/toolbars";
import Dialog from "~/components/generic/Dialog";
import {
  useConfirmProjectsImportMutation,
  useExportProjectTemplateLazyQuery,
  useProjectsImportMutation,
} from "~/components/Portfolio/PortfolioAPI.generated";
import { green, yellow } from "~/styles/theme/color";

export interface ProjectsImportModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  portfolioId: string;
}

export const ProjectsImportModal: React.FC<ProjectsImportModalProps> = ({
  isOpen,
  onRequestClose,
  portfolioId,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [importResult, setImportResult] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<"upload" | "results">(
    "upload"
  );
  const [error, setError] = useState<string | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [importProjects] = useProjectsImportMutation();
  const [confirmImport] = useConfirmProjectsImportMutation();
  const [exportTemplate] = useExportProjectTemplateLazyQuery({
    onCompleted: (data) => {
      const fileContent = data?.exportProjectImportTemplate?.fileContent;
      if (fileContent) {
        const byteCharacters = atob(fileContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "projects_template.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("No file content received");
      }
    },
    onError: (error) => {
      console.error("Error exporting template:", error);
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleImport = async () => {
    if (file) {
      try {
        const response = await importProjects({
          variables: {
            portfolioId,
            file,
          },
        });
        if (response.data?.importProjects?.errors) {
          const filteredErrors = response.data.importProjects.errors.filter(
            (err: string | null): err is string => err !== null
          );
          setErrors(filteredErrors);
        }
        const parsedResult = response.data?.importProjects?.result
          ? JSON.parse(response.data.importProjects.result)
          : null;
        setImportResult(parsedResult);
        setCurrentPage("results");
      } catch (error) {
        console.error("Error importing projects:", error);
        setError(error.message);
      }
    }
  };

  const handleConfirm = async () => {
    if (file) {
      try {
        await confirmImport({
          variables: {
            portfolioId,
            file,
          },
        });
        onRequestClose();
      } catch (error) {
        console.error("Error confirming import:", error);
      }
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      header="Import Projects"
      widthVariant="large"
      actions={
        <Toolbar>
          {currentPage === "upload" ? (
            <>
              <SecondaryButton
                onClick={() => exportTemplate({ variables: { portfolioId } })}
              >
                Export Template
              </SecondaryButton>
              <SecondaryButton onClick={onRequestClose}>Cancel</SecondaryButton>
              <PrimaryButton onClick={handleImport} style={{ marginLeft: 8 }}>
                Import
              </PrimaryButton>
            </>
          ) : (
            <>
              <SecondaryButton onClick={() => setCurrentPage("upload")}>
                Back
              </SecondaryButton>
              <PrimaryButton onClick={handleConfirm} style={{ marginLeft: 8 }}>
                Confirm
              </PrimaryButton>
            </>
          )}
        </Toolbar>
      }
    >
      {currentPage === "upload" ? (
        <div>
          <InputLabel>Select XLSX to import</InputLabel>
          <Input
            type="file"
            onChange={handleFileChange}
            inputProps={{ accept: ".xlsx" }}
          />
          {error && (
            <div className="alert alert-danger">
              Invalid value in template: {error}. Please update or contact
              support@dock.energy
            </div>
          )}
        </div>
      ) : (
        <>
          <ResultsTable results={importResult} />
          {errors.length > 0 && (
            <div className="alert alert-danger">
              <ul>
                {errors.map((err, index) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </Dialog>
  );
};

const ScrollableContainer = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
    text-align: left;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #ddd;
  }

  td span {
    display: block;
    padding: 4px;
  }
`;

const ResultsTable: React.FC<{ results: any }> = ({ results }) => {
  return (
    <ScrollableContainer>
      <StyledTable>
        <thead>
          <tr>
            <th></th> {/* 0th column with no header text */}
            {results.diff_headers.map((header: string, index: number) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {results.rows.map((row: any, index: number) => (
            <tr
              key={index}
              style={{
                backgroundColor:
                  row.import_type === "new"
                    ? green
                    : row.import_type === "update"
                    ? yellow
                    : "white",
              }}
            >
              <td>{row.import_type === "new" ? "New" : "Update"}</td>
              {Object.keys(row.raw_values).map((key: string) => (
                <td key={key.trim()}>
                  {row.raw_values[key] !== null ? row.raw_values[key] : ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </ScrollableContainer>
  );
};
