import { TextField, Tooltip } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import React, { useRef, useState } from "react";
import styled from "styled-components";

import { WorkflowStatus } from "~/api/types.generated";
import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { InputLabel } from "~/components/common/inputs";
import { Text } from "~/components/common/text";
import { Toolbar } from "~/components/common/toolbars";
import Dialog from "~/components/generic/Dialog";
import { usePortfolioUsersQuery } from "~/components/Portfolio/PortfolioAPI.generated";
import {
  getWorkflowStatusDisplay,
  usableStates,
} from "~/components/Projects/constants";

import { UpdatePortfolio } from "./useUpdatePortfolio";

function getEnumKeys<T extends string, TEnumValue extends string | number>(
  enumVariable: { [key in T]: TEnumValue }
) {
  return Object.keys(enumVariable) as Array<T>;
}

export interface UpdatePortfolioDialogProps extends UpdatePortfolio {
  portfolioId: string;
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex: 1 1 50%;
  padding: 0 10px;
`;

const CustomInputLabel = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;

  & > input {
    margin-right: 8px;
  }

  & > span {
    display: inline-block;
  }

  & ${Text} {
    margin-bottom: 0;
  }
`;

export const UpdatePortfolioDialog = React.memo(function UpdatePortfolioDialog(
  props: UpdatePortfolioDialogProps
) {
  const {
    isOpen,
    status,
    name,
    enableProjects,
    enableQAndA,
    dealManager,
    systemSize,
    removeSystemSize,
    error,
    disabled,
    orgSlug,
  } = props;
  const statusDisplay = getWorkflowStatusDisplay(orgSlug);
  const { submit, reset } = props;
  const [selectStatus, setSelectStatus] = useState<WorkflowStatus>(
    status || WorkflowStatus.Draft
  );
  const [inputName, setInputName] = useState(name || "");
  const [inputEnableProjects, setInputEnableProjects] = useState(
    enableProjects || false
  );
  const [inputEnableQAndA, setInputEnableQAndA] = useState(
    enableQAndA || false
  );
  const [inputDealManager, setInputDealManager] = useState(dealManager || "");
  const [inputSystemSize, setInputSystemSize] = useState(systemSize || 0);
  const [inputRemoveSystemSize, setInputRemoveSystemSize] = useState(
    removeSystemSize || false
  );
  const { data, loading } = usePortfolioUsersQuery({
    variables: { portfolioId: props.portfolioId },
  });

  const input = useRef<HTMLInputElement>(null);

  const close = () => {
    reset();
  };

  return (
    <Dialog
      isOpen={isOpen}
      header="Update"
      onRequestClose={close}
      actions={
        <Toolbar>
          <SecondaryButton onClick={close}>Cancel</SecondaryButton>
          <PrimaryButton
            disabled={disabled}
            onClick={() =>
              submit(
                selectStatus,
                inputName,
                inputEnableProjects,
                inputEnableQAndA,
                inputDealManager,
                inputSystemSize,
                inputRemoveSystemSize
              )
            }
          >
            Update
          </PrimaryButton>
        </Toolbar>
      }
    >
      {error && <div className="alert alert-danger">{error}</div>}
      <Container>
        <Column>
          <InputLabel>
            <Text>Deal Status</Text>
            <Select
              variant="outlined"
              ref={input}
              native
              value={selectStatus}
              onChange={(event) =>
                setSelectStatus(event.target.value as WorkflowStatus)
              }
              label="status"
            >
              {getEnumKeys(WorkflowStatus)
                .filter((key) => usableStates.includes(key as any))
                .map((key) => (
                  <option
                    key={key}
                    aria-label="None"
                    value={WorkflowStatus[key]}
                  >{`${statusDisplay[WorkflowStatus[key]]}`}</option>
                ))}
            </Select>
          </InputLabel>
          <InputLabel>
            <Text>Name</Text>
            <TextField
              variant="outlined"
              value={inputName}
              onChange={(event) => setInputName(event.target.value)}
            />
          </InputLabel>
          <CustomInputLabel>
            <input
              type="checkbox"
              checked={inputEnableProjects}
              onChange={(event) => setInputEnableProjects(event.target.checked)}
            />
            <Text>Enable Projects</Text>
          </CustomInputLabel>
          <CustomInputLabel>
            <input
              type="checkbox"
              checked={inputEnableQAndA}
              onChange={(event) => setInputEnableQAndA(event.target.checked)}
            />
            <Text>Enable Q&A</Text>
          </CustomInputLabel>
        </Column>
        <Column>
          <InputLabel>
            <Text>Deal Manager</Text>
            <Select
              variant="outlined"
              native
              value={inputDealManager}
              onChange={(event) =>
                setInputDealManager(event.target.value as string)
              }
              label="dealManager"
            >
              {loading ? (
                <option>Loading...</option>
              ) : (
                data?.portfolioUsers?.map((user: any) => (
                  <option key={user.id} value={user.id}>
                    {user.fullName}
                  </option>
                ))
              )}
            </Select>
          </InputLabel>
          <InputLabel>
            <Text>System Size</Text>
            <TextField
              variant="outlined"
              type="number"
              value={inputSystemSize}
              onChange={(event) =>
                setInputSystemSize(parseFloat(event.target.value) || 0)
              }
              disabled={inputRemoveSystemSize}
            />
          </InputLabel>
          <CustomInputLabel>
            <Tooltip title="Selecting this will compute the system size dynamically from the corresponding projects">
              <input
                type="checkbox"
                checked={inputRemoveSystemSize}
                onChange={(event) =>
                  setInputRemoveSystemSize(event.target.checked)
                }
              />
            </Tooltip>
            <Text>Dynamic System Size</Text>
          </CustomInputLabel>
        </Column>
      </Container>
    </Dialog>
  );
});
