// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { SubpagesMenu_HoldCoSubpage_Fragment, SubpagesMenu_Subpage_Fragment } from './SubpagesMenu.generated';
import { gql } from '@apollo/client';
import { SubpagesMenuFragmentDoc } from './SubpagesMenu.generated';
export type SubpageOverview_HoldCoSubpage_Fragment = (
  { readonly __typename: 'HoldCoSubpage' }
  & Pick<Types.HoldCoSubpage, 'id' | 'slug' | 'title' | 'description'>
);

export type SubpageOverview_Subpage_Fragment = (
  { readonly __typename: 'Subpage' }
  & Pick<Types.Subpage, 'id' | 'slug' | 'title' | 'description'>
);

export type SubpageOverviewFragment = SubpageOverview_HoldCoSubpage_Fragment | SubpageOverview_Subpage_Fragment;

export type FundEntityOverview_HoldingCompany_Fragment = (
  { readonly __typename: 'HoldingCompany' }
  & Pick<Types.HoldingCompany, 'id' | 'name' | 'hasSubpages'>
  & { readonly subpages?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'HoldCoSubpage' }
    & SubpageOverview_HoldCoSubpage_Fragment
    & SubpagesMenu_HoldCoSubpage_Fragment
  ) | (
    { readonly __typename: 'Subpage' }
    & SubpageOverview_Subpage_Fragment
    & SubpagesMenu_Subpage_Fragment
  )>>, readonly netYields?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'NetYield' }
    & Pick<Types.NetYield, 'id' | 'year' | 'actualEnergy' | 'p50' | 'p90'>
  )>>, readonly projects?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Project' }
    & Pick<Types.Project, 'id' | 'name' | 'capacity' | 'type'>
    & { readonly location: (
      { readonly __typename: 'ProjectLocation' }
      & Pick<Types.ProjectLocation, 'latitude' | 'longitude'>
    ) }
  )>> }
);

export type FundEntityOverview_Portfolio_Fragment = (
  { readonly __typename: 'Portfolio' }
  & Pick<Types.Portfolio, 'id' | 'name' | 'hasSubpages'>
  & { readonly subpages?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'HoldCoSubpage' }
    & SubpageOverview_HoldCoSubpage_Fragment
    & SubpagesMenu_HoldCoSubpage_Fragment
  ) | (
    { readonly __typename: 'Subpage' }
    & SubpageOverview_Subpage_Fragment
    & SubpagesMenu_Subpage_Fragment
  )>>, readonly netYields?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'NetYield' }
    & Pick<Types.NetYield, 'id' | 'year' | 'actualEnergy' | 'p50' | 'p90'>
  )>>, readonly projects?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Project' }
    & Pick<Types.Project, 'id' | 'name' | 'capacity' | 'type'>
    & { readonly location: (
      { readonly __typename: 'ProjectLocation' }
      & Pick<Types.ProjectLocation, 'latitude' | 'longitude'>
    ) }
  )>> }
);

export type FundEntityOverviewFragment = FundEntityOverview_HoldingCompany_Fragment | FundEntityOverview_Portfolio_Fragment;

export const SubpageOverviewFragmentDoc = gql`
    fragment SubpageOverview on SubpageInterface {
  id
  slug
  title
  description
}
    `;
export const FundEntityOverviewFragmentDoc = gql`
    fragment FundEntityOverview on FundEntity {
  id
  name
  hasSubpages
  subpages {
    ...SubpageOverview
    ...SubpagesMenu
  }
  netYields {
    id
    year
    actualEnergy
    p50
    p90
  }
  projects {
    id
    name
    capacity
    type
    location {
      latitude
      longitude
    }
  }
}
    ${SubpageOverviewFragmentDoc}
${SubpagesMenuFragmentDoc}`;