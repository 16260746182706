export enum WorkflowStatus {
  DRAFT = 1,
  ACQUIRED = 2,
  DILIGENCE = 4,
  SOLD = 6,
  MARKET = 6,
  DELETED = 7,
}

export enum WorkflowStatusDisplay {
  DRAFT = "Draft",
  ACQUIRED = "Acquired",
  DILIGENCE = "Diligence",
  SOLD = "Sold",
  MARKET = "Market",
  DELETED = "Deleted",
}

export enum AltWorkflowStatusDisplay {
  DRAFT = "Draft",
  ACQUIRED = "Closed",
  DILIGENCE = "Active Marketing",
  SOLD = "Sold",
  MARKET = "Pre-Marketing",
  DELETED = "Deleted",
}

export const getWorkflowStatusDisplay = (orgSlug: string) => {
  return orgSlug === "segue-infra"
    ? AltWorkflowStatusDisplay
    : WorkflowStatusDisplay;
};

export const usableStates = [
  WorkflowStatusDisplay.DRAFT,
  WorkflowStatusDisplay.MARKET,
  WorkflowStatusDisplay.ACQUIRED,
  WorkflowStatusDisplay.DILIGENCE,
];
