import React from "react";
import styled from "styled-components";

import { MenuButton, SecondaryButton } from "~/components/common/buttons";
import {
  MenuTooltipContainer,
  useMenuTooltip,
} from "~/components/common/tooltips";
import { PortfolioHeaderFragment } from "~/components/Portfolio/PortfolioHeader.generated";
import { bgColor, border, fgColor } from "~/styles/mixins";
import { black } from "~/styles/theme/color";

export interface AdminMenuProps {
  dealAdmin: boolean;
  showAdminLink: boolean;
  showUsersGroups: boolean;
  portfolio: PortfolioHeaderFragment;
  open: () => void;
}

export const AdminMenu = React.memo(function AdminMenu(props: AdminMenuProps) {
  const { dealAdmin, showAdminLink, showUsersGroups, portfolio, open } = props;

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    useMenuTooltip();

  return (
    <>
      <DropdownButton ref={setTriggerRef}>Admin</DropdownButton>
      {visible && (
        <MenuTooltipContainer ref={setTooltipRef} {...getTooltipProps()}>
          {dealAdmin && (
            <MenuButton
              onClick={(e) => {
                e.preventDefault();
                open();
              }}
            >
              Make changes
            </MenuButton>
          )}
          {showAdminLink && (
            <MenuButton
              as="a"
              href={`/tagauks/portfolios/specialpurposeentity/${portfolio.id}/change/`}
            >
              Edit content
            </MenuButton>
          )}
          {showAdminLink && (
            <MenuButton
              as="a"
              href={`/tagauks/portfolios/specialpurposeentity/${portfolio.id}/import/`}
            >
              Import projects
            </MenuButton>
          )}
          {showUsersGroups && (
            <MenuButton
              as="a"
              href={`/portfolios/${portfolio.slug}/deal-teams`}
            >
              Users & Groups
            </MenuButton>
          )}
          {showUsersGroups && (
            <MenuButton
              as="a"
              href={`/portfolios/${portfolio.slug}/documents-analytics`}
            >
              Analytics
            </MenuButton>
          )}
        </MenuTooltipContainer>
      )}
    </>
  );
});

const DropdownButton = styled(SecondaryButton)`
  padding: 0.375rem 0.75rem;
  ${bgColor.black()};
  ${fgColor.yellow()};
  ${border.yellow()};
  &:after {
    display: inline-block;
    margin-left: 0.5em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    @include caret;
  }
  &.active,
  &:hover {
    ${bgColor.yellow()};
    ${fgColor.yellow()};
    color: ${black};
  }
`;
