import classnames from "classnames";
import {
  MB_GL_LIGHT,
  MB_GL_SATELLITE,
  MINI_MAP_HEIGHT,
  MINI_MAP_WIDTH,
} from "components/maps/constants";
import { getMarkerStyle } from "components/maps/functions";
import gql from "graphql-tag";
import useLatest from "hooks/useLatest";
import { maxBy, minBy } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactMapGL, {
  FlyToInterpolator,
  MapRef,
  Marker,
  NavigationControl,
  Popup,
  ViewportProps,
  WebMercatorViewport,
} from "react-map-gl";
import { Link, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { formatSize } from "utils/calculations";

import MapLegend from "~/components/Projects/MapLegend";
import {
  DEFAULT_VIEW_PORT,
  USA_BOUNDS,
  calculateMiniMapViewPort,
} from "~/components/Projects/utils/map";
import useDimensions from "~/hooks/useDimensions";
import useLocalStorage from "~/hooks/useLocalStorage";

import { ProjectsMapFragment } from "./ProjectsMap.generated";

export const PROJECTS_MAP_FRAGMENT = gql`
  fragment ProjectsMap on ProjectsCollection {
    projects {
      id
      name
      capacity
      type
      isOwned
      location {
        id
        latitude
        longitude
      }
    }
  }
`;

export interface ProjectsMapProps {
  projects: ProjectsMapFragment["projects"];
}

const ProjectsMap = ({ projects }: ProjectsMapProps) => {
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const [mapStyle, setMapStyle] = useLocalStorage("map-style", MB_GL_LIGHT);

  // For purposes of fitting to bounded box, put a ref on the map:
  const [ref, dimensions] = useDimensions({ liveMeasure: true });
  const mapRef = useRef<MapRef>(null);
  const totalSize = useMemo(
    () =>
      projects
        ? (projects || []).reduce((acc, obj) => acc + (obj.capacity ?? 0), 0)
        : 0,
    [projects]
  );
  const projectCount = useMemo(
    () => (projects ? projects.length : 0),
    [projects]
  );

  const [viewport, setViewport] = useState<any>(DEFAULT_VIEW_PORT);
  const [selectedProject, setSelectedProject] = useState<any>(null);

  const latestViewport = useLatest(viewport);

  useEffect(() => {
    // This effect initializes the viewport when we have projects data:
    if (!projects || !dimensions || projects.length === 0) {
      return;
    }
    const { maxLat, minLat, maxLng, minLng } = {
      maxLat: (
        maxBy(projects ?? [], "location.latitude") ?? {
          location: { latitude: null },
        }
      ).location.latitude,
      minLat: (
        minBy(projects ?? [], "location.latitude") ?? {
          location: { latitude: null },
        }
      ).location.latitude,
      maxLng: (
        maxBy(projects ?? [], "location.longitude") ?? {
          location: { longitude: null },
        }
      ).location.longitude,
      minLng: (
        minBy(projects ?? [], "location.longitude") ?? {
          location: { longitude: null },
        }
      ).location.longitude,
    };
    const transition = {
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator(),
    };
    const southwest: [number, number] = [minLng ?? -180, minLat ?? -85];
    const northeast: [number, number] = [maxLng ?? 180, maxLat ?? 85];
    // center on middle of the US initially. We should use fitBounds however not available with react-map-gl:
    const vp = new WebMercatorViewport({
      width: dimensions.width,
      height: dimensions.height,
    });
    const bounds: [[number, number], [number, number]] = [northeast, southwest];
    const { longitude, latitude, zoom } = vp.fitBounds(bounds, {
      padding: 20,
      offset: [0, -100],
    });
    const newViewport = {
      longitude,
      latitude,
      zoom,
      ...transition,
    };
    setViewport(newViewport);
  }, [projects, dimensions, dimensions?.width, dimensions?.height]);

  // Derive the miniViewPort from the main map:
  const map = mapRef.current?.getMap();
  const mapBounds = map?.getBounds();
  /* eslint-disable-next-line no-underscore-dangle */
  const [neLng, neLat, swLng, swLat] = mapBounds
    ? [
        mapBounds._ne.lng,
        mapBounds._ne.lat,
        mapBounds._sw.lng,
        mapBounds._sw.lat,
      ]
    : USA_BOUNDS;
  const miniViewPort = calculateMiniMapViewPort(neLng, neLat, swLng, swLat);
  const [debouncedMiniViewport] = useDebounce(miniViewPort, 1000, {
    leading: true,
  });

  /* eslint-enable react-hooks/exhaustive-deps */
  const onDoubleClick = useCallback(
    (clickedProject) => {
      // Only use the ref inside the callback:
      const newZoom =
        latestViewport.current.zoom < 8
          ? 8
          : latestViewport.current.zoom * 1.75;
      setViewport({
        ...latestViewport.current,
        latitude: clickedProject.location.latitude,
        longitude: clickedProject.location.longitude,
        zoom: newZoom,
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator(),
      });
    },
    [latestViewport]
  );

  // useMemo to cache project data markers (optimizes for lots of projects)
  const markers = useMemo(
    () =>
      (projects || []).map(
        (p) =>
          p.location.latitude &&
          p.location.longitude && (
            <Marker
              key={p.id}
              longitude={p.location.longitude}
              latitude={p.location.latitude}
            >
              <div
                style={getMarkerStyle(p.capacity ?? 0, totalSize, projectCount)}
                onClick={() => setSelectedProject(p)}
                onDoubleClick={() => onDoubleClick(p)}
              >
                <div
                  className={classnames("map-marker", p.isOwned && "owned")}
                />
              </div>
            </Marker>
          )
      ),
    [projects, totalSize, projectCount, onDoubleClick]
  );

  const toggleMapStyle = () => {
    let newStyle = MB_GL_SATELLITE;
    if (mapStyle === MB_GL_SATELLITE) newStyle = MB_GL_LIGHT;
    setMapStyle(newStyle);
  };

  return (
    <div
      className={classnames("project-section", "map")}
      style={{ padding: 0, borderRadius: 4, border: "1px solid white" }}
    >
      <div
        ref={ref}
        className="map-container"
        style={{ height: "95%", width: "100%" }}
      >
        {viewport && (
          <ReactMapGL
            ref={mapRef}
            mapboxApiAccessToken={DJ_CONST.MAPBOX_ACCESS_TOKEN}
            mapStyle={mapStyle}
            {...viewport}
            width="100%"
            height="100%"
            onViewportChange={(newViewport: ViewportProps) =>
              setViewport(newViewport)
            }
          >
            <div style={{ position: "absolute", right: 24, top: 24 }}>
              <NavigationControl showCompass={false} />
            </div>
            <div
              className={classnames(
                "mini-map",
                mapStyle === MB_GL_SATELLITE && "light"
              )}
              onClick={toggleMapStyle}
            >
              <ReactMapGL
                style={{ borderRadius: 8, zIndex: 7 }}
                mapboxApiAccessToken={DJ_CONST.MAPBOX_ACCESS_TOKEN}
                mapStyle={
                  mapStyle === MB_GL_SATELLITE ? MB_GL_LIGHT : MB_GL_SATELLITE
                }
                {...debouncedMiniViewport}
                width={MINI_MAP_WIDTH}
                height={MINI_MAP_HEIGHT}
              >
                <div className="mini-map-caption">
                  {mapStyle === MB_GL_SATELLITE ? "Map" : "Satellite"}
                </div>
              </ReactMapGL>
            </div>
            {markers}
            {selectedProject !== null && (
              <Popup
                longitude={selectedProject.location.longitude}
                latitude={selectedProject.location.latitude}
                closeOnClick={false}
                onClose={() => setSelectedProject(null)}
              >
                <div>
                  <Link to={`/${orgSlug}/projects/${selectedProject.id}`}>
                    {selectedProject.name}
                  </Link>
                </div>
                <div>
                  {`System Size: ${formatSize(selectedProject.capacity)}`}
                </div>
              </Popup>
            )}
          </ReactMapGL>
        )}
      </div>
      <div className="item">
        <MapLegend showSelected={false} direction="row" />
      </div>
    </div>
  );
};

export default ProjectsMap;
