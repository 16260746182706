export const ProjectTypeDisplay = (type: string | undefined | null): string => {
  switch (type) {
    case "SOLAR":
      return "Solar";
    case "STORAGE":
      return "Storage";
    case "SOLAR_PLUS_STORAGE":
      return "Solar + Storage";
    default:
      return "";
  }
};

export const ProjectStatusDisplay = (
  status: string | undefined | null
): string => {
  switch (status) {
    case "NEW_BUILD":
      return "New Build";
    case "OPERATING":
      return "Operating";
    default:
      return "";
  }
};
