import React, { useState } from "react";
import styled from "styled-components";

import { DocumentTreeModal } from "~/components/Admin/Portfolios/DocumentTreeModal";
import { ProjectsImportModal } from "~/components/Admin/Portfolios/ProjectsImportModal";
import { MenuButton, TertiaryButton } from "~/components/common/buttons";
import {
  DownloadIcon,
  FileDocIcon,
  MoreIcon,
  PencilIcon,
  TrashIcon,
} from "~/components/common/icons";
import {
  MenuTooltipContainer,
  useMenuTooltip,
} from "~/components/common/tooltips";
import { DraftPortfolioFragment } from "~/components/Portfolio/PortfolioAPI.generated";
import { gray100 } from "~/styles/theme/color";

export interface PortfolioMenuProps {
  portfolio: DraftPortfolioFragment;
  loading: boolean;
  onEdit(portfolio: DraftPortfolioFragment): void;
  onDelete(portfolioId: string): void;
}

export const PortfolioMenu = React.memo(function PortfolioMenu(
  props: PortfolioMenuProps
) {
  const { portfolio, onEdit, onDelete, loading } = props;
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    useMenuTooltip();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isDocumentTreeModalOpen, setIsDocumentTreeModalOpen] = useState(false);

  return (
    <>
      <DropdownButton ref={setTriggerRef} children={<MoreIcon />} />
      {visible && (
        <MenuTooltipContainer ref={setTooltipRef} {...getTooltipProps()}>
          <MenuButton onClick={() => onEdit(portfolio)} disabled={loading}>
            <PencilIcon />
            <span>Edit</span>
          </MenuButton>
          <MenuButton
            onClick={() => setIsDocumentTreeModalOpen(true)}
            disabled={loading}
          >
            <FileDocIcon />
            <span>Configure Documents</span>
          </MenuButton>
          <MenuButton
            onClick={() => setIsImportModalOpen(true)}
            disabled={loading}
          >
            <DownloadIcon />
            <span>Import Projects</span>
          </MenuButton>
          <MenuButton
            danger
            onClick={() => onDelete(portfolio.id)}
            disabled={loading}
          >
            <TrashIcon />
            <span>Delete</span>
          </MenuButton>
        </MenuTooltipContainer>
      )}
      <ProjectsImportModal
        isOpen={isImportModalOpen}
        onRequestClose={() => setIsImportModalOpen(false)}
        portfolioId={portfolio.id}
      />
      <DocumentTreeModal
        isOpen={isDocumentTreeModalOpen}
        onRequestClose={() => setIsDocumentTreeModalOpen(false)}
        portfolio={portfolio}
      />
    </>
  );
});

const DropdownButton = styled(TertiaryButton)`
  background-color: ${gray100};
`;
