// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { PortfolioHeaderFragment } from './PortfolioHeader.generated';
import { gql } from '@apollo/client';
import { PortfolioHeaderFragmentDoc } from './PortfolioHeader.generated';
import * as Apollo from '@apollo/client';
export type UserFragmentFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'fullName'>
);

export type DraftPortfolioFragment = (
  { readonly __typename: 'Portfolio' }
  & Pick<Types.Portfolio, 'createdDate'>
  & { readonly documentsTree?: Types.Maybe<(
    { readonly __typename: 'DocumentTree' }
    & Pick<Types.DocumentTree, 'id' | 'permissionsType'>
  )> }
  & PortfolioHeaderFragment
);

export type PortfolioUsersQueryVariables = Types.Exact<{
  portfolioId: Types.Scalars['ID'];
}>;


export type PortfolioUsersQuery = (
  { readonly __typename: 'Query' }
  & { readonly portfolioUsers?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'User' }
    & UserFragmentFragment
  )>> }
);

export type OrgUsersQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
}>;


export type OrgUsersQuery = (
  { readonly __typename: 'Query' }
  & { readonly orgUsers?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'User' }
    & UserFragmentFragment
  )>> }
);

export type DraftPortfoliosQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  search?: Types.Maybe<Types.Scalars['String']>;
}>;


export type DraftPortfoliosQuery = (
  { readonly __typename: 'Query' }
  & { readonly portfolios?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Portfolio' }
    & DraftPortfolioFragment
  )>> }
);

export type ExportProjectTemplateQueryVariables = Types.Exact<{
  portfolioId: Types.Scalars['ID'];
}>;


export type ExportProjectTemplateQuery = (
  { readonly __typename: 'Query' }
  & { readonly exportProjectImportTemplate?: Types.Maybe<(
    { readonly __typename: 'ExportProjectImportTemplate' }
    & Pick<Types.ExportProjectImportTemplate, 'fileContent' | 'error'>
  )> }
);

export type CreatePortfolioMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  name: Types.Scalars['String'];
  dealManagerId?: Types.Maybe<Types.Scalars['ID']>;
}>;


export type CreatePortfolioMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createPortfolio?: Types.Maybe<(
    { readonly __typename: 'Portfolio' }
    & Pick<Types.Portfolio, 'id' | 'name' | 'createdDate'>
    & { readonly dealManager?: Types.Maybe<(
      { readonly __typename: 'User' }
      & Pick<Types.User, 'id' | 'fullName'>
    )> }
  )> }
);

export type UpdatePortfolioMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  portfolioId: Types.Scalars['ID'];
  status?: Types.Maybe<Types.WorkflowStatus>;
  name?: Types.Maybe<Types.Scalars['String']>;
  enableProjects?: Types.Maybe<Types.Scalars['Boolean']>;
  enableQAndA?: Types.Maybe<Types.Scalars['Boolean']>;
  dealManagerId?: Types.Maybe<Types.Scalars['ID']>;
  systemSize?: Types.Maybe<Types.Scalars['Float']>;
  removeSystemSize?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type UpdatePortfolioMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updatePortfolio?: Types.Maybe<(
    { readonly __typename: 'Portfolio' }
    & Pick<Types.Portfolio, 'id' | 'status' | 'name' | 'enableProjects' | 'enableQAndA' | 'systemSize'>
    & { readonly dealManager?: Types.Maybe<(
      { readonly __typename: 'User' }
      & Pick<Types.User, 'id'>
    )> }
  )> }
);

export type DeletePortfolioMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  portfolioId: Types.Scalars['ID'];
}>;


export type DeletePortfolioMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deletePortfolio?: Types.Maybe<(
    { readonly __typename: 'Portfolio' }
    & Pick<Types.Portfolio, 'id' | 'name'>
  )> }
);

export type ProjectsImportMutationVariables = Types.Exact<{
  portfolioId: Types.Scalars['ID'];
  file: Types.Scalars['Upload'];
}>;


export type ProjectsImportMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly importProjects?: Types.Maybe<(
    { readonly __typename: 'ImportProjectsMutation' }
    & Pick<Types.ImportProjectsMutation, 'errors' | 'warnings' | 'result'>
  )> }
);

export type ConfirmProjectsImportMutationVariables = Types.Exact<{
  portfolioId: Types.Scalars['ID'];
  file: Types.Scalars['Upload'];
}>;


export type ConfirmProjectsImportMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly confirmImportProjects?: Types.Maybe<(
    { readonly __typename: 'ConfirmImportProjectsMutation' }
    & Pick<Types.ConfirmImportProjectsMutation, 'success'>
  )> }
);

export type CreatePortfolioDocumentTreeMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  portfolioId: Types.Scalars['ID'];
  permissionType: Types.PermissionsSetup;
}>;


export type CreatePortfolioDocumentTreeMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createDocumentTree?: Types.Maybe<(
    { readonly __typename: 'CreatePortfolioDocumentTreeMutation' }
    & { readonly documentTree?: Types.Maybe<(
      { readonly __typename: 'DocumentTree' }
      & Pick<Types.DocumentTree, 'id' | 'permissionsType'>
    )> }
  )> }
);

export type UpdatePortfolioDocumentTreeMutationVariables = Types.Exact<{
  treeId: Types.Scalars['ID'];
  permissionType: Types.PermissionsSetup;
}>;


export type UpdatePortfolioDocumentTreeMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateDocumentTree?: Types.Maybe<(
    { readonly __typename: 'UpdatePortfolioDocumentTreeMutation' }
    & { readonly documentTree?: Types.Maybe<(
      { readonly __typename: 'DocumentTree' }
      & Pick<Types.DocumentTree, 'id' | 'permissionsType'>
    )> }
  )> }
);

export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  fullName
}
    `;
export const DraftPortfolioFragmentDoc = gql`
    fragment DraftPortfolio on Portfolio {
  ...PortfolioHeader
  createdDate
  documentsTree {
    id
    permissionsType
  }
}
    ${PortfolioHeaderFragmentDoc}`;
export const PortfolioUsersDocument = gql`
    query PortfolioUsers($portfolioId: ID!) {
  portfolioUsers(portfolioId: $portfolioId) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __usePortfolioUsersQuery__
 *
 * To run a query within a React component, call `usePortfolioUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioUsersQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function usePortfolioUsersQuery(baseOptions: Apollo.QueryHookOptions<PortfolioUsersQuery, PortfolioUsersQueryVariables>) {
        return Apollo.useQuery<PortfolioUsersQuery, PortfolioUsersQueryVariables>(PortfolioUsersDocument, baseOptions);
      }
export function usePortfolioUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortfolioUsersQuery, PortfolioUsersQueryVariables>) {
          return Apollo.useLazyQuery<PortfolioUsersQuery, PortfolioUsersQueryVariables>(PortfolioUsersDocument, baseOptions);
        }
export type PortfolioUsersQueryHookResult = ReturnType<typeof usePortfolioUsersQuery>;
export type PortfolioUsersLazyQueryHookResult = ReturnType<typeof usePortfolioUsersLazyQuery>;
export type PortfolioUsersQueryResult = Apollo.QueryResult<PortfolioUsersQuery, PortfolioUsersQueryVariables>;
export const OrgUsersDocument = gql`
    query OrgUsers($orgSlug: String!) {
  orgUsers(orgSlug: $orgSlug) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useOrgUsersQuery__
 *
 * To run a query within a React component, call `useOrgUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUsersQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useOrgUsersQuery(baseOptions: Apollo.QueryHookOptions<OrgUsersQuery, OrgUsersQueryVariables>) {
        return Apollo.useQuery<OrgUsersQuery, OrgUsersQueryVariables>(OrgUsersDocument, baseOptions);
      }
export function useOrgUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgUsersQuery, OrgUsersQueryVariables>) {
          return Apollo.useLazyQuery<OrgUsersQuery, OrgUsersQueryVariables>(OrgUsersDocument, baseOptions);
        }
export type OrgUsersQueryHookResult = ReturnType<typeof useOrgUsersQuery>;
export type OrgUsersLazyQueryHookResult = ReturnType<typeof useOrgUsersLazyQuery>;
export type OrgUsersQueryResult = Apollo.QueryResult<OrgUsersQuery, OrgUsersQueryVariables>;
export const DraftPortfoliosDocument = gql`
    query DraftPortfolios($orgSlug: String!, $search: String) {
  portfolios(workflowStatus: 1, orgSlug: $orgSlug, search: $search) {
    ...DraftPortfolio
  }
}
    ${DraftPortfolioFragmentDoc}`;

/**
 * __useDraftPortfoliosQuery__
 *
 * To run a query within a React component, call `useDraftPortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftPortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftPortfoliosQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDraftPortfoliosQuery(baseOptions: Apollo.QueryHookOptions<DraftPortfoliosQuery, DraftPortfoliosQueryVariables>) {
        return Apollo.useQuery<DraftPortfoliosQuery, DraftPortfoliosQueryVariables>(DraftPortfoliosDocument, baseOptions);
      }
export function useDraftPortfoliosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DraftPortfoliosQuery, DraftPortfoliosQueryVariables>) {
          return Apollo.useLazyQuery<DraftPortfoliosQuery, DraftPortfoliosQueryVariables>(DraftPortfoliosDocument, baseOptions);
        }
export type DraftPortfoliosQueryHookResult = ReturnType<typeof useDraftPortfoliosQuery>;
export type DraftPortfoliosLazyQueryHookResult = ReturnType<typeof useDraftPortfoliosLazyQuery>;
export type DraftPortfoliosQueryResult = Apollo.QueryResult<DraftPortfoliosQuery, DraftPortfoliosQueryVariables>;
export const ExportProjectTemplateDocument = gql`
    query ExportProjectTemplate($portfolioId: ID!) {
  exportProjectImportTemplate(portfolioId: $portfolioId) {
    fileContent
    error
  }
}
    `;

/**
 * __useExportProjectTemplateQuery__
 *
 * To run a query within a React component, call `useExportProjectTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportProjectTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportProjectTemplateQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useExportProjectTemplateQuery(baseOptions: Apollo.QueryHookOptions<ExportProjectTemplateQuery, ExportProjectTemplateQueryVariables>) {
        return Apollo.useQuery<ExportProjectTemplateQuery, ExportProjectTemplateQueryVariables>(ExportProjectTemplateDocument, baseOptions);
      }
export function useExportProjectTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportProjectTemplateQuery, ExportProjectTemplateQueryVariables>) {
          return Apollo.useLazyQuery<ExportProjectTemplateQuery, ExportProjectTemplateQueryVariables>(ExportProjectTemplateDocument, baseOptions);
        }
export type ExportProjectTemplateQueryHookResult = ReturnType<typeof useExportProjectTemplateQuery>;
export type ExportProjectTemplateLazyQueryHookResult = ReturnType<typeof useExportProjectTemplateLazyQuery>;
export type ExportProjectTemplateQueryResult = Apollo.QueryResult<ExportProjectTemplateQuery, ExportProjectTemplateQueryVariables>;
export const CreatePortfolioDocument = gql`
    mutation CreatePortfolio($orgSlug: String!, $name: String!, $dealManagerId: ID) {
  createPortfolio(orgSlug: $orgSlug, name: $name, dealManagerId: $dealManagerId) {
    id
    name
    createdDate
    dealManager {
      id
      fullName
    }
  }
}
    `;
export type CreatePortfolioMutationFn = Apollo.MutationFunction<CreatePortfolioMutation, CreatePortfolioMutationVariables>;

/**
 * __useCreatePortfolioMutation__
 *
 * To run a mutation, you first call `useCreatePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPortfolioMutation, { data, loading, error }] = useCreatePortfolioMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      name: // value for 'name'
 *      dealManagerId: // value for 'dealManagerId'
 *   },
 * });
 */
export function useCreatePortfolioMutation(baseOptions?: Apollo.MutationHookOptions<CreatePortfolioMutation, CreatePortfolioMutationVariables>) {
        return Apollo.useMutation<CreatePortfolioMutation, CreatePortfolioMutationVariables>(CreatePortfolioDocument, baseOptions);
      }
export type CreatePortfolioMutationHookResult = ReturnType<typeof useCreatePortfolioMutation>;
export type CreatePortfolioMutationResult = Apollo.MutationResult<CreatePortfolioMutation>;
export type CreatePortfolioMutationOptions = Apollo.BaseMutationOptions<CreatePortfolioMutation, CreatePortfolioMutationVariables>;
export const UpdatePortfolioDocument = gql`
    mutation UpdatePortfolio($orgSlug: String!, $portfolioId: ID!, $status: WorkflowStatus, $name: String, $enableProjects: Boolean, $enableQAndA: Boolean, $dealManagerId: ID, $systemSize: Float, $removeSystemSize: Boolean) {
  updatePortfolio(
    orgSlug: $orgSlug
    portfolioId: $portfolioId
    status: $status
    name: $name
    enableProjects: $enableProjects
    enableQAndA: $enableQAndA
    dealManagerId: $dealManagerId
    systemSize: $systemSize
    removeSystemSize: $removeSystemSize
  ) {
    id
    status
    name
    enableProjects
    enableQAndA
    dealManager {
      id
    }
    systemSize
  }
}
    `;
export type UpdatePortfolioMutationFn = Apollo.MutationFunction<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>;

/**
 * __useUpdatePortfolioMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioMutation, { data, loading, error }] = useUpdatePortfolioMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      portfolioId: // value for 'portfolioId'
 *      status: // value for 'status'
 *      name: // value for 'name'
 *      enableProjects: // value for 'enableProjects'
 *      enableQAndA: // value for 'enableQAndA'
 *      dealManagerId: // value for 'dealManagerId'
 *      systemSize: // value for 'systemSize'
 *      removeSystemSize: // value for 'removeSystemSize'
 *   },
 * });
 */
export function useUpdatePortfolioMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>) {
        return Apollo.useMutation<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>(UpdatePortfolioDocument, baseOptions);
      }
export type UpdatePortfolioMutationHookResult = ReturnType<typeof useUpdatePortfolioMutation>;
export type UpdatePortfolioMutationResult = Apollo.MutationResult<UpdatePortfolioMutation>;
export type UpdatePortfolioMutationOptions = Apollo.BaseMutationOptions<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>;
export const DeletePortfolioDocument = gql`
    mutation DeletePortfolio($orgSlug: String!, $portfolioId: ID!) {
  deletePortfolio(orgSlug: $orgSlug, portfolioId: $portfolioId) {
    id
    name
  }
}
    `;
export type DeletePortfolioMutationFn = Apollo.MutationFunction<DeletePortfolioMutation, DeletePortfolioMutationVariables>;

/**
 * __useDeletePortfolioMutation__
 *
 * To run a mutation, you first call `useDeletePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePortfolioMutation, { data, loading, error }] = useDeletePortfolioMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useDeletePortfolioMutation(baseOptions?: Apollo.MutationHookOptions<DeletePortfolioMutation, DeletePortfolioMutationVariables>) {
        return Apollo.useMutation<DeletePortfolioMutation, DeletePortfolioMutationVariables>(DeletePortfolioDocument, baseOptions);
      }
export type DeletePortfolioMutationHookResult = ReturnType<typeof useDeletePortfolioMutation>;
export type DeletePortfolioMutationResult = Apollo.MutationResult<DeletePortfolioMutation>;
export type DeletePortfolioMutationOptions = Apollo.BaseMutationOptions<DeletePortfolioMutation, DeletePortfolioMutationVariables>;
export const ProjectsImportDocument = gql`
    mutation ProjectsImport($portfolioId: ID!, $file: Upload!) {
  importProjects(portfolioId: $portfolioId, importFile: $file) {
    errors
    warnings
    result
  }
}
    `;
export type ProjectsImportMutationFn = Apollo.MutationFunction<ProjectsImportMutation, ProjectsImportMutationVariables>;

/**
 * __useProjectsImportMutation__
 *
 * To run a mutation, you first call `useProjectsImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectsImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectsImportMutation, { data, loading, error }] = useProjectsImportMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useProjectsImportMutation(baseOptions?: Apollo.MutationHookOptions<ProjectsImportMutation, ProjectsImportMutationVariables>) {
        return Apollo.useMutation<ProjectsImportMutation, ProjectsImportMutationVariables>(ProjectsImportDocument, baseOptions);
      }
export type ProjectsImportMutationHookResult = ReturnType<typeof useProjectsImportMutation>;
export type ProjectsImportMutationResult = Apollo.MutationResult<ProjectsImportMutation>;
export type ProjectsImportMutationOptions = Apollo.BaseMutationOptions<ProjectsImportMutation, ProjectsImportMutationVariables>;
export const ConfirmProjectsImportDocument = gql`
    mutation ConfirmProjectsImport($portfolioId: ID!, $file: Upload!) {
  confirmImportProjects(portfolioId: $portfolioId, importFile: $file) {
    success
  }
}
    `;
export type ConfirmProjectsImportMutationFn = Apollo.MutationFunction<ConfirmProjectsImportMutation, ConfirmProjectsImportMutationVariables>;

/**
 * __useConfirmProjectsImportMutation__
 *
 * To run a mutation, you first call `useConfirmProjectsImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmProjectsImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmProjectsImportMutation, { data, loading, error }] = useConfirmProjectsImportMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useConfirmProjectsImportMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmProjectsImportMutation, ConfirmProjectsImportMutationVariables>) {
        return Apollo.useMutation<ConfirmProjectsImportMutation, ConfirmProjectsImportMutationVariables>(ConfirmProjectsImportDocument, baseOptions);
      }
export type ConfirmProjectsImportMutationHookResult = ReturnType<typeof useConfirmProjectsImportMutation>;
export type ConfirmProjectsImportMutationResult = Apollo.MutationResult<ConfirmProjectsImportMutation>;
export type ConfirmProjectsImportMutationOptions = Apollo.BaseMutationOptions<ConfirmProjectsImportMutation, ConfirmProjectsImportMutationVariables>;
export const CreatePortfolioDocumentTreeDocument = gql`
    mutation CreatePortfolioDocumentTree($orgSlug: String!, $portfolioId: ID!, $permissionType: PermissionsSetup!) {
  createDocumentTree(
    orgSlug: $orgSlug
    portfolioId: $portfolioId
    permissionType: $permissionType
  ) {
    documentTree {
      id
      permissionsType
    }
  }
}
    `;
export type CreatePortfolioDocumentTreeMutationFn = Apollo.MutationFunction<CreatePortfolioDocumentTreeMutation, CreatePortfolioDocumentTreeMutationVariables>;

/**
 * __useCreatePortfolioDocumentTreeMutation__
 *
 * To run a mutation, you first call `useCreatePortfolioDocumentTreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePortfolioDocumentTreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPortfolioDocumentTreeMutation, { data, loading, error }] = useCreatePortfolioDocumentTreeMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      portfolioId: // value for 'portfolioId'
 *      permissionType: // value for 'permissionType'
 *   },
 * });
 */
export function useCreatePortfolioDocumentTreeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePortfolioDocumentTreeMutation, CreatePortfolioDocumentTreeMutationVariables>) {
        return Apollo.useMutation<CreatePortfolioDocumentTreeMutation, CreatePortfolioDocumentTreeMutationVariables>(CreatePortfolioDocumentTreeDocument, baseOptions);
      }
export type CreatePortfolioDocumentTreeMutationHookResult = ReturnType<typeof useCreatePortfolioDocumentTreeMutation>;
export type CreatePortfolioDocumentTreeMutationResult = Apollo.MutationResult<CreatePortfolioDocumentTreeMutation>;
export type CreatePortfolioDocumentTreeMutationOptions = Apollo.BaseMutationOptions<CreatePortfolioDocumentTreeMutation, CreatePortfolioDocumentTreeMutationVariables>;
export const UpdatePortfolioDocumentTreeDocument = gql`
    mutation UpdatePortfolioDocumentTree($treeId: ID!, $permissionType: PermissionsSetup!) {
  updateDocumentTree(treeId: $treeId, permissionType: $permissionType) {
    documentTree {
      id
      permissionsType
    }
  }
}
    `;
export type UpdatePortfolioDocumentTreeMutationFn = Apollo.MutationFunction<UpdatePortfolioDocumentTreeMutation, UpdatePortfolioDocumentTreeMutationVariables>;

/**
 * __useUpdatePortfolioDocumentTreeMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioDocumentTreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioDocumentTreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioDocumentTreeMutation, { data, loading, error }] = useUpdatePortfolioDocumentTreeMutation({
 *   variables: {
 *      treeId: // value for 'treeId'
 *      permissionType: // value for 'permissionType'
 *   },
 * });
 */
export function useUpdatePortfolioDocumentTreeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePortfolioDocumentTreeMutation, UpdatePortfolioDocumentTreeMutationVariables>) {
        return Apollo.useMutation<UpdatePortfolioDocumentTreeMutation, UpdatePortfolioDocumentTreeMutationVariables>(UpdatePortfolioDocumentTreeDocument, baseOptions);
      }
export type UpdatePortfolioDocumentTreeMutationHookResult = ReturnType<typeof useUpdatePortfolioDocumentTreeMutation>;
export type UpdatePortfolioDocumentTreeMutationResult = Apollo.MutationResult<UpdatePortfolioDocumentTreeMutation>;
export type UpdatePortfolioDocumentTreeMutationOptions = Apollo.BaseMutationOptions<UpdatePortfolioDocumentTreeMutation, UpdatePortfolioDocumentTreeMutationVariables>;