import { ellipsis } from "polished";
import React, { useContext } from "react";
import styled, { css } from "styled-components";

import BaseDropdownButton from "~/components/menus/DropdownButton";
import ItemMenu from "~/components/QAndA/components/ItemMenu";
import {
  bgColor,
  border,
  borderRadius,
  fgColor,
  focusOutline,
} from "~/styles/mixins";

import ProjectFilterContext from "../context/ProjectFilterContext";

const ProjectFilter = () => {
  const { availableProjects, selection } = useContext(ProjectFilterContext);

  const currentProjects = selection.values();
  const allSelected = selection.allSelected || !selection.anySelected;
  const oneSelected = !allSelected && selection.count === 1;

  return (
    <DropdownButton
      hasSelection={selection.anySelected}
      dropdown={
        <ItemMenu
          title="Project"
          anchor="bottom right"
          origin="top right"
          currentItems={currentProjects}
          availableItems={availableProjects}
          onSelectItem={selection.select}
          onSelectAll={selection.selectAll}
          onDeselectItem={selection.deselect}
          onDeselectAll={selection.deselectAll}
        />
      }
    >
      Project:{" "}
      {allSelected ? "All" : oneSelected ? currentProjects[0].name : "Multiple"}{" "}
      {String.fromCharCode(9662)}
    </DropdownButton>
  );
};

const DropdownButton = styled(BaseDropdownButton).withConfig<{
  hasSelection: boolean;
}>({
  shouldForwardProp: (prop) => prop !== "hasSelection",
})`
  ${borderRadius()}
  ${focusOutline()};
  ${bgColor.transparent()};
  ${border.transparent()};
  ${ellipsis()};
  height: 1.75rem;

  &:hover {
    ${bgColor.gray300()};
  }

  &[aria-expanded="true"] {
    ${bgColor.primary()};
    ${fgColor.white()};
  }

  ${(props) =>
    props.hasSelection &&
    css`
      ${bgColor.white()};
      ${fgColor.primary()};
      ${border.primary()};
    `}
`;

export default ProjectFilter;
