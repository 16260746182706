// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type ProjectsTableFragment = (
  { readonly __typename: 'ProjectsCollection' }
  & { readonly projects?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Project' }
    & Pick<Types.Project, 'id' | 'name' | 'status' | 'type' | 'capacity'>
    & { readonly location: (
      { readonly __typename: 'ProjectLocation' }
      & Pick<Types.ProjectLocation, 'id' | 'city' | 'state'>
    ) }
  )>> }
);

export const ProjectsTableFragmentDoc = gql`
    fragment ProjectsTable on ProjectsCollection {
  projects {
    id
    name
    status
    type
    capacity
    location {
      id
      city
      state
    }
  }
}
    `;