import classnames from "classnames";
import React, { MutableRefObject, useContext } from "react";

import { CellButton, DataGrid, GridScroller } from "~/components/DataGrid";
import { QAndASubjectFragment } from "~/components/QAndA/QAndA.generated";
import { Direction, ariaSort, createOrdering, reverse } from "~/utils/ordering";

import { useColumnSelect } from "../context/ColumnSelectContext";
import OrderingContext, { Field } from "../context/OrderingContext";
import { ChildQuestion, ParentQuestion } from "../context/QuestionContext";
import EmptyState from "./EmptyState";
import LoadingState from "./LoadingState";
import QuestionRow from "./QuestionRow";

const { Header, Row, HeaderCell, Body } = DataGrid;

export interface QuestionsGridProps {
  questions: Array<ParentQuestion | ChildQuestion>;
  loading: boolean;
  canEditQuestions: boolean;
  canEditQuestionAnswers: boolean;
  gridRef?: MutableRefObject<HTMLTableElement | null>;
  subject: QAndASubjectFragment;
}

const QuestionsGrid = ({
  questions,
  loading,
  gridRef,
  subject,
  canEditQuestions,
  canEditQuestionAnswers,
}: QuestionsGridProps) => {
  const orderingContext = useContext(OrderingContext);
  const { ordering, setOrdering } = orderingContext;
  const { selectedColumns } = useColumnSelect();

  if (loading) return <LoadingState />;

  if (!questions || questions.length === 0) {
    return <EmptyState text={"No questions so far"} />;
  }

  const sortByNumber = () => {
    setOrdering(
      ordering.field === Field.NUMBER
        ? reverse(ordering)
        : createOrdering(Field.NUMBER, Direction.ASC)
    );
  };

  return (
    <GridScroller>
      <DataGrid innerRef={gridRef}>
        <Header>
          <Row>
            {selectedColumns.number && (
              <HeaderCell numeric aria-sort={ariaSort(Field.NUMBER, ordering)}>
                <CellButton onClick={sortByNumber}>
                  No
                  <i
                    className={classnames(
                      "icon",
                      "icon--sort-up",
                      ordering.field === Field.NUMBER && {
                        active: true,
                        asc: ordering.direction === Direction.ASC,
                        desc: ordering.direction === Direction.DESC,
                      }
                    )}
                  />
                </CellButton>
              </HeaderCell>
            )}
            {selectedColumns.status && <HeaderCell padded>Status</HeaderCell>}
            {selectedColumns.category && (
              <HeaderCell padded>Category / Topic</HeaderCell>
            )}
            {selectedColumns.project && subject.__typename === "Portfolio" && (
              <HeaderCell padded>Project</HeaderCell>
            )}
            {selectedColumns.project &&
              subject.__typename === "HoldingCompany" && (
                <HeaderCell padded>Portfolio</HeaderCell>
              )}
            {selectedColumns.question && (
              <HeaderCell padded>Question / Request</HeaderCell>
            )}
            {selectedColumns.answer && <HeaderCell padded>Answer</HeaderCell>}
            {selectedColumns.createdAt && (
              <HeaderCell padded>Created At</HeaderCell>
            )}
            {selectedColumns.modifiedAt && (
              <HeaderCell padded>Modified At</HeaderCell>
            )}
          </Row>
        </Header>
        <Body>
          {questions?.map((question) => (
            <QuestionRow
              canEditQuestions={canEditQuestions}
              canEditQuestionAnswers={canEditQuestionAnswers}
              key={question.id}
              question={question}
              subject={subject}
              selectedColumns={selectedColumns}
            />
          ))}
        </Body>
      </DataGrid>
    </GridScroller>
  );
};

export default QuestionsGrid;
