// Update ColumnSelectContext to load initial state from local storage
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export const defaultColumns = {
  number: true,
  status: true,
  category: true,
  project: true,
  question: true,
  answer: true,
  createdAt: false,
  modifiedAt: false,
};

const LOCAL_STORAGE_KEY = "selectedColumns";

const loadColumnsFromLocalStorage = () => {
  const savedColumns = localStorage.getItem(LOCAL_STORAGE_KEY);
  return savedColumns ? JSON.parse(savedColumns) : defaultColumns;
};

interface ColumnSelectContextType {
  selectedColumns: typeof defaultColumns;
  toggleColumn: (column: keyof typeof defaultColumns) => void;
}

const ColumnSelectContext = createContext<ColumnSelectContextType>({
  selectedColumns: defaultColumns,
  toggleColumn: () => {},
});

interface ColumnSelectProviderProps {
  children: ReactNode;
}

export const ColumnSelectProvider: React.FC<ColumnSelectProviderProps> = ({
  children,
}) => {
  const [selectedColumns, setSelectedColumns] = useState(
    loadColumnsFromLocalStorage
  );

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(selectedColumns));
  }, [selectedColumns]);

  const toggleColumn = (column: keyof typeof defaultColumns) => {
    setSelectedColumns((prev: typeof defaultColumns) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const value = useMemo(
    () => ({ selectedColumns, toggleColumn }),
    [selectedColumns]
  );

  return (
    <ColumnSelectContext.Provider value={value}>
      {children}
    </ColumnSelectContext.Provider>
  );
};

export const useColumnSelect = () => useContext(ColumnSelectContext);
