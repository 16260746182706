import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { useState } from "react";

import { PermissionsSetup } from "~/api/types.generated";
import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { Toolbar } from "~/components/common/toolbars";
import Dialog from "~/components/generic/Dialog";
import {
  DraftPortfolioFragment,
  useCreatePortfolioDocumentTreeMutation,
  useUpdatePortfolioDocumentTreeMutation,
} from "~/components/Portfolio/PortfolioAPI.generated";

export interface DocumentTreeModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  portfolio: DraftPortfolioFragment;
}

export const DocumentTreeModal: React.FC<DocumentTreeModalProps> = ({
  isOpen,
  onRequestClose,
  portfolio,
}) => {
  const [permissionType, setPermissionType] = useState<PermissionsSetup>(
    PermissionsSetup.Role
  );
  const [createDocumentTree] = useCreatePortfolioDocumentTreeMutation();
  const [updateDocumentTree] = useUpdatePortfolioDocumentTreeMutation();

  const handleSave = async () => {
    if (portfolio?.documentsTree) {
      await updateDocumentTree({
        variables: {
          treeId: portfolio.documentsTree.id,
          permissionType: permissionType,
        },
        refetchQueries: ["DraftPortfolios"],
      });
    } else {
      await createDocumentTree({
        variables: {
          orgSlug: portfolio.org.slug,
          portfolioId: portfolio.id,
          permissionType: permissionType,
        },
        refetchQueries: ["DraftPortfolios"],
      });
    }
    onRequestClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      header={portfolio.documentsTree ? "Update Documents" : "Create Documents"}
      actions={
        <Toolbar>
          <SecondaryButton onClick={onRequestClose}>Close</SecondaryButton>
          <PrimaryButton onClick={handleSave}>
            {portfolio.documentsTree ? "Save" : "Create"}
          </PrimaryButton>
        </Toolbar>
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <label style={{ marginRight: "10px" }}>Permission Type:</label>
        <Select
          value={permissionType}
          onChange={(event) =>
            setPermissionType(event.target.value as PermissionsSetup)
          }
        >
          <MenuItem value={PermissionsSetup.Role}>Role</MenuItem>
          <MenuItem value={PermissionsSetup.Group}>Group</MenuItem>
        </Select>
      </div>
    </Dialog>
  );
};
